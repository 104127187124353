import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const SBHLandingPage = () => (
  <PhysicianLandingPage
    physician="Sheri Hovdestad"
    institution="SBH Counseling"
    referralCode="SBH"
    physicianURL="https://sbhcounseling.com/about-sheri"
  />
)

export default SBHLandingPage
